<template>
  <Layout class="InvitationList">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item>
          <el-input
            v-model="formData.redeemCode"
            clearable
            placeholder="兑换码"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.codeStatus"
            placeholder="状态"
            clearable
          >
            <el-option
              v-for="(item, index) in statusOptions"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="primary"
          @click="drawerVisible = true"
        >
          添加
        </el-button>
        <el-button
          type="primary"
          @click="handleExport"
        >
          导出
        </el-button>
      </template>
    </BHeader>
    <div class="overview">
      <i class="fa fa-barcode" />
      <span>{{ redeemInfo.name }}</span>
      ·
      <span>{{ redeemInfo.start }} - {{ redeemInfo.end }}</span>
      ·
      <span>{{ redeemInfo.total }}</span>
    </div>
    <el-table
      ref="table"
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
    >
      <el-table-column
        prop="redeemCode"
        label="兑换码"
      />
      <el-table-column
        prop="codeStatus"
        label="状态"
      >
        <template #default="{row}">
          {{ (statusOptionMap[row.codeStatus] || {}).name || '未定义' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="userIdStr"
        label="用户ID"
      >
        <template #default="{row}">
          {{ (row.userIdStr === '0' || row.userIdStr === 0 || !row.userIdStr) ? '-' : row.userIdStr }}
        </template>
      </el-table-column>
      <el-table-column
        prop="redeemTime"
        label="兑换时间"
        width="160px"
      >
        <template #default="{row}">
          {{ dataFomat(row.redeemTime) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160px"
      >
        <template #default="{row}">
          {{ dataFomat(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="userCount"
        label="操作"
      >
        <template #default="{row}">
          <BTextButton
            :loading="row.deleteLoading"
            :title="row.codeStatus === 1 ? '删除' : '已兑换, 不可操作'"
            :icon="`fa ${row.codeStatus === 1 ? 'fa-trash-o' : 'fa-ban'}`"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
    <el-drawer
      title="添加兑换码"
      :visible.sync="drawerVisible"
      direction="rtl"
      size="400px"
    >
      <el-form
        ref="add"
        :model="newRedeemForm"
        :rules="newRedeemFormRules"
      >
        <el-form-item
          label="追加兑换码数量"
          prop="codeNum"
        >
          <el-input-number
            v-model="newRedeemForm.codeNum"
            :min="1"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleAddRedeemSubmit"
          >
            确定添加
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </Layout>
</template>

<script>
import { cloneDeep } from 'lodash'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import { getRedeemCodeList, deleteRedeemCode, batchCreateRedeemCode } from '@/api/CouponsAndRedeemCode.js'
import { batchQueryParamsForExport } from '@/utils'

export default {
  name: 'RedeemCodeList',
  data () {
    return {
      statusOptions: [
        { name: '未兑换', value: 1 },
        { name: '已兑换', value: 2 }
      ],
      statusOptionMap: {
        1: { name: '未兑换', value: 1 },
        2: { name: '已兑换', value: 2 }
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      batchUploadTemplate: {
        list: [],
        header: [
          '兑换码',
          '状态',
          '用户ID',
          '兑换时间',
          '创建时间'
        ],
        fields: [
          'redeemCode',
          'codeStatus',
          'userIdStr',
          'redeemTime',
          'createTime'
        ]
      },
      redeemInfo: {},
      drawerVisible: false,
      newRedeemForm: {},
      newRedeemFormRules: {
        codeNum: [{ required: true, message: '新增数量必填' }]
      }
    }
  },
  created () {
    this.redeemInfo = this.$route.query
    this.handleSearch()
  },
  methods: {
    dataFomat (date) {
      if (date === '0000-00-00 00:00:00') { return '-' }
      return date
    },
    queryDataList () {
      this.listInfo.loading = true
      const activityId = this.$route.query.id
      getRedeemCodeList({ ...this.formData, activityId })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
            this.redeemInfo.total = this.listInfo.total
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    handleDeleteItem (row) {
      if (row.codeStatus !== 1) return false
      this.$confirm('此操作将删除兑换码, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          deleteRedeemCode({
            activityId: this.redeemInfo.id,
            id: row.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.handleSearch()
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    },
    queryLogListForExport () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.listInfo.loading = true
            const formData = cloneDeep(this.formData)
            const activityId = this.$route.query.id

            const paramsList = batchQueryParamsForExport({
              params: {
                ...formData,
                activityId,
                pageSize: 100
              },
              field: 'pageNum',
              maxSize: 100000,
              total: this.listInfo.total
            })

            Promise.all(paramsList.map(params => getRedeemCodeList(params)))
              .then(resultList => {
                const result = resultList.reduce((res, item) => {
                  return res.concat(item.data.list)
                }, [])
                resolve(result)
              })
              .finally(() => {
                this.listInfo.loading = false
              })
          } else {
            resolve(false)
          }
        })
      })
    },
    handleExport () {
      this.queryLogListForExport()
        .then(data => {
          this.formatToDwonload(data)
          this.downloadExcel()
        })
    },
    formatToDwonload (data) {
      const temp = cloneDeep(data)
      temp.forEach(item => {
        item.codeStatus = (this.statusOptionMap[item.codeStatus] || {}).name || '未定义'
        item.createTime = this.dataFomat(item.createTime)
        item.redeemTime = this.dataFomat(item.redeemTime)
        item.userIdStr = (item.userIdStr === '0' || item.userIdStr === 0 || !item.userIdStr) ? '-' : item.userIdStr
      })
      this.batchUploadTemplate.list = temp
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, '兑换码列表' + '_' + new Date().toLocaleDateString())
    },
    handleAddRedeemSubmit () {
      this.$refs.add.validate(valid => {
        if (valid) {
          const activityId = this.$route.query.id
          batchCreateRedeemCode({
            activityId,
            ...this.newRedeemForm
          }).then(res => {
            if (res.code === 200) {
              this.drawerVisible = false
              this.handleSearch()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .InvitationList{
    .overview {
      background: #eee;
      width: fit-content;
      min-width: 400px;
      height: 50px;
      line-height: 50px;
      border-radius: 0 50px 0 0;
      padding: 0 50px 0 20px;
      font-size: 14px;
      >i {
        color: cadetblue;
        margin-right: 20px;
      }
    }
  }
</style>
